.button-to-right{
  float: right;
}

.webplayer-radio-button {
  width: 50%;
  text-align: center;
  overflow: hidden;
}

.webplayer-radio-button-group {
  width: 100%;
  overflow: hidden;
  z-index: 0;
  padding-top: 6px;
}

.player-overview-action-buttons {
  margin-left: 8px;
  width: 130px;
  overflow: hidden;
}

.playercard-is-selected-checked-icon {
  display: block;
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  color: #E6E7E8;
  font-size: 36px;
}

.playercard-is-selected {
  background-color: rgba(55, 162, 219, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.style-button-as-link {
  color: rgba(0, 0, 0, 0.65);
  border: none;
  padding: 0 !important;
  cursor: pointer;
  outline: none;
}

.style-button-as-link:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.65);
}