.property {
  display: flex;
  flex-direction: column;
  padding: 6px;
  margin: 6px 0px;
  border-bottom: 1px black solid;
}

.property__delete {
  display: flex;
  justify-content: flex-end;
}

.property__attribute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}

.property__attribute__key {
  width: 40%;
  
}
.property__attribute__key::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.property__attribute__value {
  width: 50%;
}

.property__attribute__enum-values {
  width: 50%;
  margin: 0px 6px;
}
.property__enum-values {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 0;
}