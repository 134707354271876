.setup2fa-box {
  display: flex;
  flex-direction: column;
  text-align: start !important;
  min-width: 70%;
  padding: 2%;
}

.setup2fa-subbox {
  background-color: #d9d9d9;
  border-radius: 6px;
  padding: 2%;
}

.setup2fa-app-icons {
  color: #1890ff !important;
  font-size: 46px;
}

/* @media screen and (min-width: 1200px) {
  .setup2fa-vertical-divider {
    border-left: 1px solid rgba(0, 0, 0, 0.85);
  }
} */

.setup2fa-box .ant-steps-item-tail {
  top: 24px !important;
}

.setup2fa-box .ant-steps-item-tail::after {
  height: 5px !important;
}

.setup2fa-box .ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 67px !important;
  padding: 3.5px 40px !important;
}

.setup2fa-box .ant-steps-label-vertical .ant-steps-item-content {
  width: 125px;
}

.setup2fa-box .ant-steps-item-process > .ant-steps-item-tail::after {
  background-color: #aca8a8;
}

.setup2fa-box .ant-steps-item-wait > .ant-steps-item-tail::after {
  background-color: #aca8a8;
}
